<template>
  <header id="header" class="header">
    <div class="header_wrap">
      <div class="container f">
        <div class="lside f">
          <Logo aria-label="Home link in header" />
          <Nav />
        </div>
        <div class="rside f fe fg gap8">
          <button
              class="btn btn-transparent f20 hlgt px10"
              type="button"
              @click="openSearchModal"
          >
            <i class="i i-search"></i>
          </button>
          <button
              aria-label="Menu"
              class="profile-nav_top trg_btn f fc btn_burger p0 wh52 hlgt"
              @click="openModal(ModalIds.MOBILE_NAV)"
          >
            <span class=""></span>
          </button>

          <FilterTopComponentsSearch class="hlgd" header />
          <Currency class="hlgd" />
          <LanguageDD class="hlgd" />
          <HeaderComponentsLogin class="hlgd" />
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import Logo from './Components/Logo.vue'
import Nav from './Components/Nav.vue'
import Currency from './Components/CurrencyDD.vue'
import {useResize} from '~/composables/resize.js'
import LanguageDD from "~/components/Header/Components/LanguageDD.vue";
import {useModalStore} from "~/store/modal";
import {ModalIds} from "~/types/Modals";

const currW = useResize()
const {openModal} = useModalStore()
const openSearchModal = () => {
  openModal(ModalIds.MOBILE_SEARCH)
}
</script>

<style lang="scss" scoped>


.header {
  background: var(--accent-on-primary);
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;

  &_wrap {
    padding: 1.5rem 0;
    background: var(--accent-on-primary);
    position: relative;
    z-index: 15;
  }

  .btn_burger {
    padding: 1.6rem;

    span {
      height: 2px;
      width: 100%;
      background: var(--accent-primary);
      position: relative;
      transition: 0.3s;

      &:before {
        content: '';
        display: block;
        height: inherit;
        width: inherit;
        background: inherit;
        position: absolute;
        left: 0;
        top: -6px;
        transition: 0.3s;
      }

      &:after {
        content: '';
        display: block;
        height: inherit;
        width: inherit;
        background: inherit;
        position: absolute;
        left: 0;
        bottom: -6px;
        transition: 0.3s;
      }
    }

    //}
  }
}

@media screen and (max-width: 1250px) {
  .header {
    &_wrap {
      border-bottom: 1px solid var(--surfaces-high-container);

      .nav {
        display: none;
      }
    }
  }
}


@media screen and (max-width: 979px) {
  .header {
    &_wrap {
      border-top: none;
      padding: 0;
      height: 5.3rem;
      display: flex;
      align-items: center;

      .nav {
        display: none;
      }

      .container {
        padding-right: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.logo {
  img {
    height: 3.2rem;
  }
}
</style>
